.Product-items {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 7%;
    margin-bottom: 7%;
}

.Product-items h1{
    padding-bottom: 2%;
}

.product--image {
    width: 100%;
    height: auto;
    object-fit: cover;
    /* Set height equal to width to make images square */
    height: 100%;
    aspect-ratio: 1; /* Ensure aspect ratio is 1:1 */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
  
}

.card {
    width: calc(25% - 10px); /* Adjust width and spacing as needed */
    margin-bottom: 20px;
    text-align: center;
    transition: all 0.3s ease-in;
    padding: 18px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 3%;
}



.card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
    margin-top: 15px;
}

@media screen and (max-width: 1024px) {

    .all-Product-items{
        margin-top: 10%;
    }

    .card {
        width: calc(50% - 10px); /* 2 items in a row for iPad */
        margin-top: 5%;
       
    }
}

@media screen and (max-width: 575px) {

    .pdt-head{
        font-size: 20px;
    }

    .all-Product-items{
        margin-top: 30%;
    }
    .card {
        width: calc(90% - 10px); /* 1 item in a row for mobile */
        margin-top: 7%;
    }
}
