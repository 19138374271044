/* Team.css */

.team {
    margin-top: 7%;
}

.team-head {
    margin-left: 3%;
    margin-bottom: 5%;
}

.team-member {
    align-items: center;
    text-align: center;
}

.team-member img {
    width: 40%;
    padding: 2%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

@media (max-width: 768px) {

    .team-head{
        font-size: 18px;
    }
    .team-member {
        width: 100%; /* Make each team member take up the full width */
        margin-bottom: 20px; /* Add some spacing between team members */
    }
    .team-member p{
        font-size: 10px;;
    }

    .team-member img {
        width: 80%; /* Adjust image width for better fit in mobile view */
    }
}
