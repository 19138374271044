.Visit-location {
    margin-top: 10%;
}

.details {
    padding-top: 5%;
}

.details p {
    padding-top: 2%;
} 
.right {
    margin-left: 3%;
}


@media only screen and (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    iframe{
        height: 50vh;
        margin-top: 10%;
    }

    .left,
    .right {
        width: 100%;
        
    }
}


