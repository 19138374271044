header {
    height: 10vh;
    line-height: 10vh;
    position: fixed;
    /* Add fixed positioning */
    top: 0;
    /* Stick it to the top */
    z-index: 1000;
    width: 100%;
    background-color: white;
    /* box-shadow: -1px 11px 24px -11px rgba(0, 0, 0, 0.75); */
    transition: 0.5s;
}

.logo a h2 {
    text-decoration: none;
    color: #545454;
    font-family: 'Roboto', sans-serif;
    /* font-family: 'Sacramento', cursive; */
    font-size: xx-large;
}


header .container {
    padding-top: 15px;
}

header ul li {
    margin-left: 30px;
}

header ul li a {
    color: #545454;
    transition: 0.5s;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
}

header ul li a:hover {
    color: black;
}

.home-btn {
    padding: 18px 25px;
    border-radius: 6px;
    transition: 0.3s all ease;
    cursor: pointer;
    height: fit-content;
    color: #545454;
}

.home-btn {
    background: linear-gradient(145deg, #e2e8ec, #ffffff);
    box-shadow: 4px 4px 8px #cbcbcb, 4px -4px 8px #ffffff;
}

.home-btn :hover {
    background-color: black;
    color: white;
}

.open {
    display: none;
}

.header.active {
    height: 100px;
    /* Change this to a fixed pixel value that suits your design */
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: white;
    box-shadow: -1px 11px 24px -11px rgba(0, 0, 0, 0.75);
    transition: 0.5s;
}


@media(max-width: 768px) {

    /* .logoImg{
        display: none;
    } */

    .logoImg{
        width: 15%;
    }

    .logo a h2{
        font-size: large;
    }
    header ul {
        padding-top: 30px;
    }

    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        left: 0;
        top: 0;
        transition: all 0.5s ease-in-out;
        width: 60%;
        height: 100vh;
        background: white;
        z-index: 888;
    }

    header ul li {
        width: 100%;
        transition: 0.5s;
        text-transform: uppercase;
        line-height: 70px;
    }

    header .link {
        display: none;
    }

    .close,
    .open {
        position: absolute;
        top: 35px;
        right: 20px;
        color: black;
        display: block;
        font-size: 25px;
        z-index: 999;
    }

    /* .close {
        right: 45%;
    } */

    .close.home-btn {
        background: linear-gradient(145deg, black, #5b5656);
        color: black;
        border-radius: 50%;
        padding: 0;
        width: 60px;
        height: 60px;
        line-height: 60px;
    }

    .header.active {
        height: 10vh;
    }


}